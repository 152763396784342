import SERVICE_URL, { API_VALUES, LOG_TYPE } from "../config/API_DATA"
import HttpPost from "../http/httppost.js";
import Logger from './logger.js';
import {FILE_NAME} from "../config/API_DATA";
export default class Signin
{
    static buildRequest (pLogin,pCapText)
    {
        let returnValue = null;
        try {
            let body = {
                emailid: pLogin.logMail,
                password: pLogin.logPwd,
                orgId:API_VALUES.ORG_ID,
                enteredcaptcha: pLogin.captcha,
                captchaText: pCapText
            }
            returnValue = body;
        }
        catch (err)
        {
            let body = Logger.buildRequest( FILE_NAME.API_GET_ADMIN_SIGNIN_STATUS , "error in building body for Signin api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.GET_ADMIN_SIGNIN_STATUS, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_ADMIN_SIGNIN_STATUS , "error in getting Response from  Signin api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}