<template>
  <div>
    <div>
      <CContainer
        class="min-vh-100 d-flex align-items-center mob-res-pd0 mob-ai-flex-start mob-bg-white"
      >
        <CRow
          class="w-100 justify-content-center form-wrapper-mf mob-m0 login-form"
        >
          <CCol md="4" class="form-left">
            <div class="form-right-title">
              {{ $t("sysLogin.noAcct") }}
            </div>
            <div class="form-sub-message">{{ $t("sysLogin.desc") }}</div>
          </CCol>
          <CCol md="8" class="mob-res-form-bg">
            <div class="logo-wrapper align-center">
              <span class="brand-logo"> </span>
            </div>
            <ValidationObserver v-slot="{ handleSubmit }" v-if="showLogin">
              <div class="form-wrapper">
                <div class="form-title">
                  {{ $t("sysLogin.loginTitle") }}
                </div>
                <form @submit.prevent="handleSubmit(loginFormSubmit)">
                  <div>
                    <div class="form-field">
                      <cDropDown
                        :key="'org'"
                        :url="org"
                        :isAllowEmpty="false"
                        :ctrlCode="'value'"
                        :ctrlName="'name'"
                        :isShowCode="false"
                        :isMultiple="false"
                        :isSearchable="true"
                        v-on:input="onStatusChange"
                        class="hide-invalid-feedback"
                      />
                    </div>
                    <div class="form-field">
                      <cTextInputComponent
                        v-model="logDet.logMail"
                        :placeholder="$t('sysLogin.emailPl')"
                        rules="required|email"
                        :rule="showError"
                        :label="$t('sysLogin.email')"
                      />
                    </div>
                    <div class="form-field p-relative">
                      <div>
                        <cTextInputComponent
                          v-model="logDet.logPwd"
                          :placeholder="$t('sysLogin.pwdPl')"
                          :type="pwdType"
                          rules="required"
                          :rule="showError"
                          :label="$t('sysLogin.pwd')"
                        />
                        <div class="icon-show" @click="togglePwdType()">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style="enable-background: new 0 0 512 512"
                            xml:space="preserve"
                          >
                            <g>
                              <g>
                                <path
                                  d="M508.177,245.995C503.607,240.897,393.682,121,256,121S8.394,240.897,3.823,245.995c-5.098,5.698-5.098,14.312,0,20.01    C8.394,271.103,118.32,391,256,391s247.606-119.897,252.177-124.995C513.274,260.307,513.274,251.693,508.177,245.995z M256,361    c-57.891,0-105-47.109-105-105s47.109-105,105-105s105,47.109,105,105S313.891,361,256,361z"
                                />
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="M271,226c0-15.09,7.491-28.365,18.887-36.53C279.661,184.235,268.255,181,256,181c-41.353,0-75,33.647-75,75    c0,41.353,33.647,75,75,75c37.024,0,67.668-27.034,73.722-62.358C299.516,278.367,271,255.522,271,226z"
                                />
                              </g>
                            </g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                            <g></g>
                          </svg>
                        </div>
                      </div>
                      <div v-if="showCaptcha" class="">
                        <cTextInputComponent
                          v-model="logDet.captcha"
                          :placeholder="$t('loginPg.cptPl')"
                          rules="required"
                        />

                        <cCaptcha
                          ref="sysCaptchaComponent"
                          iconName="cil-check"
                          class="row grey-border-mf captcha agent-captcha"
                        />
                      </div>
                    </div>
                    <CAlert v-if="showAlert" show color="danger">
                      <div
                        v-if="
                          alertValue && alertValue.hasOwnProperty('valueList')
                        "
                      >
                        <p
                          v-for="(value, index) in alertValue.valueList"
                          :key="index"
                        >
                          {{ value }}
                        </p>
                      </div>
                      <div
                        v-if="
                          alertValue && alertValue.hasOwnProperty('codeList')
                        "
                      >
                        <span>{{ $t("cAlert.errorCode") + " : " }}</span
                        ><span
                          v-for="(code, index) in alertValue.codeList"
                          :key="index"
                        >
                          {{ code + " " }}
                        </span>
                      </div>
                      <div
                        v-if="alertValue && alertValue.constructor == String"
                      >
                        <p>
                          {{ alertValue }}
                        </p>
                      </div>
                    </CAlert>
                    <div class="form-field align-right mt-25px">
                      <CButton
                        pressed
                        block
                        color="success"
                        type="submit"
                        @click="showError = false"
                        class="btn-primary-mf"
                        >{{ $t("loginPg.login") }}</CButton
                      >
                    </div>
                    <CButton
                      @click="(showLogin = false), (showError = true)"
                      class="px-0 removewrap forget-password"
                      >{{ $t("loginPg.frgtPwd") }}</CButton
                    >
                  </div>
                </form>
              </div>
            </ValidationObserver>
            <div>
              <ValidationObserver v-slot="{ handleSubmit }" v-if="!showLogin">
                <form @submit.prevent="handleSubmit(forgotFormSubmit)">
                  <div class="form-wrapper">
                    <div class="form-title">
                      {{ $t("forgtPwd.frgtPwdTxt") }}
                    </div>

                    <p class="text-center">{{ $t("forgtPwd.enterMail") }}</p>
                    <div class="form-field">
                      <cTextInputComponent
                        v-model="frgtPwd.mail"
                        :placeholder="$t('forgtPwd.fgMailPl')"
                        rules="required|email"
                        label="Email"
                        :rule="showError"
                        @input="frgtPwdAlert = null"
                      />
                    </div>
                    <div v-if="frgtPwdAlert">
                      <CAlert v-if="frgtPwdAlert" show color="danger">
                        <div
                          v-if="
                            frgtPwdAlert &&
                            frgtPwdAlert.hasOwnProperty('valueList')
                          "
                        >
                          <p
                            v-for="(value, index) in frgtPwdAlert.valueList"
                            :key="index"
                          >
                            {{ value }}
                          </p>
                        </div>
                        <div
                          v-if="
                            frgtPwdAlert &&
                            frgtPwdAlert.hasOwnProperty('codeList')
                          "
                        >
                          <span>{{ $t("cAlert.errorCode") + " : " }}</span
                          ><span
                            v-for="(code, index) in frgtPwdAlert.codeList"
                            :key="index"
                          >
                            {{ code + " " }}
                          </span>
                        </div>
                        <div
                          v-if="
                            frgtPwdAlert && frgtPwdAlert.constructor == String
                          "
                        >
                          <p>
                            {{ frgtPwdAlert }}
                          </p>
                        </div>
                      </CAlert>
                    </div>
                    <div v-if="resetPwd">
                      <p class="text-center">{{ $t("forgtPwd.resetTxt") }}</p>
                      <br />
                    </div>
                    <div class="form-field align-right mt-25px">
                      <CButton
                        class="btn-primary-mf w-auto"
                        pressed
                        block
                        color="success"
                        type="submit"
                        @click="showError = false"
                        >{{ $t("forgtPwd.resetPwd") }}</CButton
                      >
                    </div>
                  </div>
                  <br />
                  <CRow class="d-flex justify-content-center">
                    <CCol col="9.5" class="top-header">
                      {{ $t("forgtPwd.backTo") }}
                    </CCol>
                    <CCol col="2.5" class="top-header-link">
                      <u>
                        <p
                          class="c-primary"
                          @click="
                            (showLogin = true),
                              (resetPwd = false),
                              (showError = true),
                              (frgtPwd.mail = null)
                          "
                        >
                          {{ $t("forgtPwd.login") }}
                        </p>
                      </u>
                    </CCol>
                  </CRow>
                  <br />
                  <br />
                </form>
              </ValidationObserver>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>

    <cAlertModal
      v-if="pwdRest"
      :buttonList="[
        { name: $t('forgtPwd.login'), func: navigateToLogin, color: 'success' },
      ]"
      :status="1"
      :show="pwdRest"
      :desc="$t('forgtPwd.mailSuccess')"
    ></cAlertModal>
  </div>
</template>

<script>
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
  getSettings,
} from "../../util/util";

import router, {
  navigate,
  updateCurrRouteQuery,
  navigateReplace,
} from "../../router/index";
import { PATHNAME } from "../../util/constants.js";
import { ValidationObserver } from "vee-validate";
import { mapActions } from "vuex";
import cCaptcha from "../../components/form/cCaptcha";
import cAlertModal from "../../components/form/cAlertModal";
import { mapGetters } from "vuex";
import cTextInputComponent from "../../components/form/cTextInputComponent";
import apiAdminSignIn from "../../services/apiAdminSignIn";
import Logger from "../../services/logger";
import SERVICE_URL, {
  LOG_TYPE,
  FILE_NAME,
  HTTP_CODES,
  API_VALUES,
} from "../../config/API_DATA";
import { COMPONENT_NAME, PASSWORD_TYPE } from "../../util/constants.js";
import {
  VALIDATE_SUCCESS_RES,
  getErrorMessage,
} from "../../util/apiValidate.js";
import apiForgotPassword from "../../services/apiForgotPassword";
import BaseConfiguration from "../../config/base";
import { initClientBasedSett } from "../../main";
import { setBaseSettToLocal } from "./../../applocalstorage/localStorage";
import cDropDown from "../../components/form/cDropDown";
import Apollo from "../../graphql/ApolloClient";
export default {
  name: COMPONENT_NAME.SYSTEM_LOGIN,
  components: {
    ValidationObserver,
    cTextInputComponent,
    cCaptcha,
    cAlertModal,
    cDropDown,
  },
  data: () => ({
    pwdRest: false,
    alertValue: null,
    showAlert: false,
    showCaptcha: false,
    showError: true,
    showLogin: true,
    resetPwd: false,
    frgtPwdAlert: null,
    logDet: {
      orgid: null,
      logMail: null,
      logPwd: null,
      captcha: null,
      captchaText: null,
    },
    frgtPwd: {
      mail: null,
    },
    regMail: null,
    pwdType: PASSWORD_TYPE.PASSWORD,
    org: SERVICE_URL.SELECT_ORG,
  }),

  methods: {
    ...mapActions("sSysLogin", ["SET_ADMIN_SIGNIN_REQ"]),
    ...mapActions("sSysLogin", ["SET_ADMIN_SIGNIN_RES"]),
    ...mapActions("sSysLogin", ["SET_FORGOTPWD"]),
    ...mapActions("sSysLogin", ["SET_ORGID"]),
    ...mapActions("sDashBoard", {
      SET_AGENT_LOGO_DET_RESPONSE: "SET_AGENT_LOGO_DET_RESPONSE",
    }),
    ...mapActions("sSettings", {
      SET_SETTINGS: "SET_SETTINGS",
    }),
    ...mapActions("sSysLogin", ["SET_SEL_PROJ"]),
    navigateToReg: function () {
      navigate(PATHNAME.AGENT_REGISTRATION);
    },

    captchaError() {
      try {
        if (BaseConfiguration.isDebug) console.log("captchaError");
        if (this.$refs.sysCaptchaComponent) {
          this.$refs.sysCaptchaComponent.newCaptcha();
          this.logDet.captcha = null;
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.SYSTEM_LOGIN,
          "error in captchaError",
          err.toString(),
          "captchaError",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    navigateToLogin: function () {
      this.showLogin = true;
      this.resetPwd = false;
      this.pwdRest = false;
      this.frgtPwd.mail = null;
      navigate(PATHNAME.SYSTEM_LOGIN);
    },

    togglePwdType() {
      if (this.pwdType == PASSWORD_TYPE.PASSWORD) {
        this.pwdType = PASSWORD_TYPE.TEXT;
      } else if (this.pwdType == PASSWORD_TYPE.TEXT) {
        this.pwdType = PASSWORD_TYPE.PASSWORD;
      }
    },

    loginFormSubmit: async function () {
      // if(this.logDet.logMail!='' && this.logDet.logPwd!='')
      // {
      if (Object.keys(router.currentRoute.query).length != 0) {
        await updateCurrRouteQuery(null);
      }

      await this.getAdminSignInStatus();
      // }
    },
    async forgotFormSubmit() {
      //this.pwdRest = true;
      //this.resetPwd = true;
      this.showLogin = false;
      if (this.frgtPwd.mail) {
        await this.getForgotPassword(this.frgtPwd.mail);
        this.SET_FORGOTPWD(this.frgtPwd.mail);
        this.regMail = this.forgotPwd;
      }
    },

    async getForgotPassword(pMail) {
      try {
        if (BaseConfiguration.isDebug) console.log("getForgotPassword");
        // let retValue;
        let pswdReq = apiForgotPassword.buildRequest(pMail);

        if (pswdReq) {
          let pswdRes = await apiForgotPassword.getResponse(pswdReq);
          if (VALIDATE_SUCCESS_RES(pswdRes)) {
            this.frgtPwdAlert = null;
            this.resetPwd = true;
            this.pwdRest = true;
            this.showAlert = false;
            this.alertValue = null;
            // this.showAcknowledgeAlert();
          } else {
            let error = getErrorMessage(pswdRes);
            let isJwt = handleJWT(error);
            if (!isJwt) {
              //this.showAlert = true;
              this.resetPwd = false;
              this.frgtPwdAlert = getErrorAlertValue(error);
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.SYSTEM_LOGIN,
          "error in getForgotPassword",
          err.toString(),
          "getForgotPassword",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      // return retValue;
    },

    onStatusChange(pStatus) {
      try {
        if (BaseConfiguration.isDebug) console.log("onStatusChange");
        if (pStatus && pStatus.value) {
          this.SET_SEL_PROJ(pStatus)
          this.logDet.orgid = pStatus.value;
          API_VALUES.ORG_ID = this.logDet.orgid;
        } else {
          throw new Error("pStatus not found");
        }

        // this.resetUserRole();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in on Status Change",
          err.toString(),
          "onStatusChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    //Get captcha list from API and store in vuex store

    async getAdminSignInStatus() {
      let retValue;
      try {
        //let langCode = get lang code

        let admSignInReq = apiAdminSignIn.buildRequest(
          this.logDet,
          this.captchaText
        );
        if (admSignInReq) {
          this.SET_ADMIN_SIGNIN_REQ(admSignInReq); //save req in store

          let admSignInRes = await apiAdminSignIn.getResponse(admSignInReq);
          if (VALIDATE_SUCCESS_RES(admSignInRes)) {
            if (admSignInRes.data.data.logoDetails) this.SET_ORGID(admSignInReq.orgId );
            this.SET_AGENT_LOGO_DET_RESPONSE(
              admSignInRes.data.data.logoDetails
            ); //save logo details in store
            this.SET_ADMIN_SIGNIN_RES(admSignInRes.data.data); //save res in store
            this.showAlert = false;
            retValue = admSignInRes.data;
            let settings = await getSettings();
            if (settings && settings.setting) {
              this.SET_SETTINGS(settings.setting);
              setBaseSettToLocal(settings.setting);
              initClientBasedSett();
              Apollo.createApolloClient();
            }
            console.log("executed");
            navigateReplace(PATHNAME.DASHBOARD);
          } else {
            admSignInRes.data.data
              ? (this.showCaptcha = true)
              : ((this.alertValue = getErrorMessage(
                  admSignInRes
                ).error[0].value),
                (this.showAlert = true));
            if (this.showCaptcha == true) {
              this.captchaError();
            }
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.API_ADMIN_SIGN_IN,
          "error in getting response from adminSignIn API",
          err.toString(),
          "getadminSignInResponse",
          LOG_TYPE.ERROR
        );
        console.log("catch error");
        Logger.getResponse(body);
      }
      return retValue;
    },
  },
  computed: {
    ...mapGetters("sCaptcha", {
      captchaRes: "GET_CAPTCHA_RES",
      captchaText: "GET_CAPTCHA_TEXT",
    }),
  },

  created() {
    document.title = this.$t("documentTitle.system");
    if (
      router.currentRoute.query.param &&
      router.currentRoute.query.param.length > 0
    ) {
      this.showAlert = true;
      if (
        router.currentRoute.query.param[0] == HTTP_CODES.ERROR_JWT_01 ||
        router.currentRoute.query.param[0] == HTTP_CODES.ERROR_JWT_02 ||
        router.currentRoute.query.param[0] == HTTP_CODES.ERROR_AUT2 ||
        router.currentRoute.query.param[0] == HTTP_CODES.ERROR_PER
      ) {
        this.alertValue = router.currentRoute.query.param[1];
      }
    } else {
      if (router.currentRoute.query["lang"]) {
        this.$store.dispatch(
          "language/setLanguage",
          router.currentRoute.query["lang"]
        );
      }
      this.showAlert = false;
    }
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.SYSTEM_LOGIN);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>

